<template>
    <div class="d-flex flex-column flex-root">
        <div
            class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
            id="kt_login"
        >
            <!--begin::Aside-->
            <div
                class="login-aside d-flex flex-column flex-row-auto"
                style="background-color: #F2C98A;"
            >
                <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                    <a href="#" class="text-center mb-10">
                        <img
                            src="media/logos/logo-letter-1.png"
                            class="max-h-70px"
                            alt=""
                        />
                    </a>
                </div>
                <div
                    class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
                    :style="{ backgroundImage: `url(${backgroundImage})` }"
                ></div>
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div
                class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
            >
                <div class="d-flex flex-column-fluid flex-center">
                    <!--begin::Signin-->
                    <div v-if="step == 1" class="login-form login-signin">
                        <form
                            class="form"
                            id="kt_login_signin_form"
                            v-on:submit.prevent="beforeLogin"
                        >
                            <div class="pb-13 pt-lg-0 pt-5">
                                <h3
                                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                                >
                                  管理者ログイン
                                </h3>
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark"
                                ><span class="star">*</span>メールアドレス
                                </label
                                >
                                <div
                                    id="example-input-group-1"
                                    label=""
                                    label-for="example-input-1"
                                >
                                    <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                                        type="text"
                                        name="email"
                                        ref="email"
                                        v-model="form.email"
                                        maxlength="200"
                                    />
                                  <div class="error-message" v-if="error.email">{{error.email[0]}}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="d-flex justify-content-between mt-n5">
                                    <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                                    ><span class="star">*</span>パスワード</label>
                                </div>
                                <div
                                    id="example-input-group-2"
                                    label=""
                                    label-for="example-input-2"
                                >
                                    <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                                        type="password"
                                        name="password"
                                        ref="password"
                                        maxlength="200"
                                        v-model="form.password"
                                        autocomplete="off"
                                    />
                                  <div class="error-message" v-if="error.password">{{error.password[0]}}</div>
                                </div>
                            </div>
                            <div class="mt-5 mb-5 text-right">
                                <router-link
                                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer"
                                    id="kt_login_forgot"
                                    :to="routeForgotPassword"
                                >PW再設定</router-link>
                            </div>
                            <div class="pb-lg-0 pb-5 ">
                                <button
                                    type="submit"
                                    ref="kt_login_signin_submit"
                                    class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                                >ログイン</button>
                            </div>
                        </form>
                    </div>
                    <div v-else-if="step == 2">
                        <form v-on:submit.prevent="updatephone">
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark"><span class="star">*</span>電話番号</label>
                                <div class="pt-4">
                                    <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="email" ref="email" v-model="form.phone"/>
                                    <div class="error-message" v-if="error.phone">{{error.phone[0]}}</div>
                                </div>
                            </div>
                            <div class="pb-lg-0 pb-5 ">
                                <button type="submit" ref="verify-submit" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">更新</button>
                            </div>
                        </form>
                    </div>
                    <div v-else-if="step == 3">
                        <form id="verify-submit" v-on:submit.prevent="submitVerify">
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark"><span class="star">*</span>電話番号に送信された認証コードを入力してください</label>
                                <div class="pt-4">
                                    <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="email" ref="email" v-model="form.sns_code" maxlength="6"/>
                                    <div class="error-message" v-if="error.sns_code">{{error.sns_code[0]}}</div>
                                </div>
                            </div>
                            <div class="mt-5 mb-5 text-right">
                                <span v-if="countdown!=0">{{ countdown }}秒後に認証コードが再送できます</span>
                                <h3 v-if="countdown==0" :disabled="isButtonDisabled" class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer" @click="renewSms">認証コードを再送します</h3>
                            </div>
                            <div class="pb-lg-0 pb-5 ">
                                <button type="submit" ref="verify-submit" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">ログイン</button>
                            </div>
                        </form>
                    </div>
                    <!--end::Signin-->

                </div>
            </div>
            <!--end::Content-->
        </div>
      <Popup id="PopupError" title="確認" :content="errorMessage" :title_button="close_popup"></Popup>
    </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import {mapGetters, mapState} from "vuex";
import {LOGIN, LOGOUT} from "@/core/services/store/auth.module";
import { STATUS_CODE } from "@/core/config/constant";
import Popup from "@/view/components/Popup";
import apiService from "@/core/services/api.service";
export default {
  name: "login-1",
  components: {Popup},
  data() {
        return {
            // Remove this dummy login info
            form: {
                email: "",
                password: "",
                phone: "",
                phone_token : "",
                sns_code : "",
            },
            errorMessage:"",
            error:{},
            routeForgotPassword:{ name: 'forgot-password'},
            close_popup: this.$t("close_popup"),
            step:1,
            countdown: 0,
            intervalId: null,
            otp_login:null

        };
    },
    computed: {
        ...mapState({
            errors: state => state.auth.errors
        }),
        ...mapGetters(["currentUser"]),
        tenantDir(){
            return this.$route.params.tenant_dir;
        },
        backgroundImage() {
            return (
                process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
            );
        }
    },
    mounted() {

    },
    created() {
        if (this.tenantDir){
            this.routeForgotPassword = { name: 'forgot-password-tenant_dir', params: { tenant_dir: this.tenantDir }}
        }

        this.checkLoginFunction()
    },
    methods: {
        beforeLogin(){
            this.otp_login ? this.submit() : this.submitVerify()
        },
        checkLoginFunction(){
            apiService.get(`login-method`)
                // go to which page after successfully login
                .then((response) => {
                    if (response.data.code == 200){
                       this.otp_login =  response.data.data.login_otp
                    }
                })
                .catch(e => {
                    this.notifyError(e)
                })
        },
        showForm(form) {
            this.state = form;
            var form_name = "kt_login_" + form + "_form";
            KTUtil.animateClass(
                KTUtil.getById(form_name),
                "animate__animated animate__backInUp"
            );
        },
      submit(){
        this.error = {}
        this.errorMessage = '';
        var email = this.form.email;
        var password = this.form.password;
          let param = {email, password}
          if (this.tenantDir){
              param.tenant_dir = this.tenantDir;
          }
          // clear existing errors
        this.$store.dispatch(LOGOUT);
        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        setTimeout(() => {
          // send login request
            apiService.post(`auth/login`,param)
              // go to which page after successfully login
              .then((response) => {
                if (response.data.code == STATUS_CODE.BAD_REQUEST){
                  this.error = response.data.data;
                }
                if (response.data.code == STATUS_CODE.HTTP_BAD_REQUEST){
                  this.notifyError(response.data.data)
                }
                  if (response.data.code == 200) {
                      this.step = response.data.data.step
                      if (this.step == 2){
                          this.form.phone_token = response.data.data.phone_token
                      }else {
                          this.startCountdown(60)
                      }

                  }
              })
              .catch(e => {
                  this.notifyError(e)
              })

          submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
          );
        }, 1000);

      },
        updatephone(){
            apiService.post(`auth/update-phone`,{
                phone : this.form.phone,
                phone_token : this.form.phone_token,
                email : this.form.email,
            })
                .then((response) => {
                    if (response.data.code == STATUS_CODE.BAD_REQUEST){
                        this.error = response.data.data;
                    }
                    if (response.data.code == 200) {
                        this.step = response.data.data.step
                        this.startCountdown(60)
                    }
                    if (response.data.code == STATUS_CODE.HTTP_BAD_REQUEST){
                      this.notifyError(response.data.data)
                    }
                })
                .catch(e => {
                this.notifyError(e)
            })

        },
        submitVerify(){
            this.error = {}
            this.loading = true
            this.$store
                .dispatch(LOGIN,{...this.form,tenant_dir : this.tenantDir})
                // go to which page after successfully login
                .then((response) => {
                    this.loading = false
                    if (response.code == STATUS_CODE.BAD_REQUEST){
                        this.error = response.data;
                    }
                    if (response.code == 400) {
                        this.notifyError(response.data)
                    }
                    if (response.data.token){
                        this.$router.push({ name: "form-answer-list" })
                    }
                })
                .catch((e) => {
                    this.loading = false
                    this.notifyError(e)
                });
        },
        renewSms(){
            if (!this.isButtonDisabled) {
                this.isButtonDisabled = true;
                this.loading = true
                apiService.post(`auth/renew-code`, {...this.form,tenant_dir : this.tenantDir}).then(response => {
                    if (response.data.code == 400 || response.data.code == 404) {
                        this.notifyError(response.data.data)
                    }
                    if (response.data.code == 200) {
                        this.form.sns_code=""
                        this.notifySuccess('操作が成功しました。')
                        this.startCountdown(60)
                    }
                }).catch(e => {
                    this.notifyError(e)
                })
                setTimeout(() => {
                    this.isButtonDisabled = false;
                }, 3000); // Thời gian chờ 1 giây (tùy chỉnh theo nhu cầu của bạn)
            }

        },
        startCountdown(duration) {
            let timeRemaining = duration;

            this.countdown = this.formatTime(timeRemaining);

            this.intervalId = setInterval(() => {
                timeRemaining--;

                if (timeRemaining < 0) {
                    clearInterval(this.intervalId);
                    this.countdown = 0;
                } else {
                    this.countdown = this.formatTime(timeRemaining);
                }
            }, 1000);
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60);
            const seconds = time % 60;
            return time;
        },
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
        this.countdown = 0;
    }
};
</script>

<style scoped>
.star{
    content: '*';
    color: #F56C6C !important;
    margin-right: 4px;
}

</style>
